import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../services/session-storage.service';
import { influencePointsURL, signInPhoneURL, signUpURL, subscribeURL } from '../constants/common.constants';
import { ModalConfig } from '../interfaces/user.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-action-sheet-modal',
  templateUrl: './action-sheet-modal.component.html',
  styleUrls: ['./action-sheet-modal.component.scss']
})
export class ActionSheetModalComponent implements OnInit {
  @Input() modalConfig: ModalConfig;
  @Output() close = new EventEmitter();

  public isComicUnlocking: boolean;
  public currentUrl: string;
  public influencePointsBalance: any;

  constructor(
    private comicService: ComicService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
  }

  public action(action: string) {
    switch (action) {
      case 'subscribe':
        this.navigateAndCloseModal(subscribeURL);
        break;
      case 'earnInfluencePoints':
        this.navigateAndCloseModal(influencePointsURL);
        break;
      case 'redirectToSignUp':
        this.navigateAndCloseModal(signUpURL);
        break;
      case 'redirectToSignIn':
        this.navigateAndCloseModal(signInPhoneURL);
        break;
      case 'useInfluencePoints':
        this.unlockComic(action);
        break;
      case 'restoreSubscription':
        // Waiting on BE for this functionality
        break;
      case 'closeModal':
        this.navigateAndCloseModal();
        break;
    }
  }

  private changeBtnLoaderState(action: string, state: boolean): void {
    this.modalConfig.buttons = this.modalConfig.buttons.map(btn => {
      if (btn.action === action) {
        btn.loading = state;
      }
      return btn;
    });
  }

  private navigateAndCloseModal(path?: string): void {
    if (path) {
      let queryParams = path === signInPhoneURL ? { comic: this.currentUrl } : {}
      this.router.navigate([path], { queryParams });
    }
    this.close.emit();
  }

  async unlockComic(action?: string) {
    if (!this.modalConfig.modalData.influencePointsBalance) return;

    this.changeBtnLoaderState(action, true);
    await this.comicService.unlockComic(`${this.currentUrl}/index.json`);
    this.toastr.success('You’ve used 1 influence point to unlock this comic');
    const urls = this.sessionStorageService.getItem('unlockedURLs') || [];
    urls.push(`${this.currentUrl}/index.json`);
    this.sessionStorageService.setItem('unlockedURLs', urls);
    setTimeout(() => {
      this.navigateAndCloseModal();
      this.changeBtnLoaderState(action, false);
      window.location.reload();
    }, 4000);
  }
}
